import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TextField as MuiTextField,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { baseUrl } from "../../../config";
import { Delete, Edit, Search } from "@mui/icons-material";
import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";
import {
  createRoad,
  deleteRoad,
  fetchRoads,
  openRoadForm,
  setRoad,
  updateRoad,
} from "../../../redux/slices/road";
import { Formik } from "formik";
import { fetchAssociation } from "../../../redux/slices/association";
import useAuth from "../../../hooks/useAuth";
import { format } from "date-fns";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

function RoadForm(props: any) {
  const dispatch = useAppDispatch();
  const { road } = useAppSelector((state) => state.road);

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (road && road.id !== undefined) {
        dispatch(updateRoad(road.id, values));
      } else {
        dispatch(createRoad(values));
      }

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      // eslint-disable-next-line
      //dispatch(openRoadForm());
    } catch (error: unknown) {
      setStatus({ sent: false });
      setErrors({ submit: "Error" });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        road
          ? {
              name: road.name,
              municipality: road.municipality,
            }
          : {
              name: "",
              municipality: "",
            }
      }
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Tiet
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    name="name"
                    label="Nimi"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="municipality"
                    label="Kunta"
                    value={values.municipality}
                    error={Boolean(touched.municipality && errors.municipality)}
                    fullWidth
                    helperText={touched.municipality && errors.municipality}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                mr={2}
              >
                Tallenna
              </Button>
              <Button
                variant="contained"
                color="error"
                mt={3}
                onClick={props.handleOpen}
              >
                Peruuta
              </Button>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

interface Property {
  user: { firstName: string; lastName: string };
  code: string;
  name: string;
  units: number;
}

function UnitList(props: any) {
  const groupedProperties: { [ownerName: string]: Property[] } = {};

  props.association.road?.properties?.forEach((property: Property) => {
    const ownerName = `${property.user.firstName} ${property.user.lastName}`;
    if (!groupedProperties[ownerName]) {
      groupedProperties[ownerName] = [];
    }
    groupedProperties[ownerName].push(property);
  });

  // Grand totals
  let grandTotalUnits = 0;
  let grandTotalUnitPay = 0;
  let grandTotalBasePay = 0;
  let grandTotalAmount = 0;

  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          {props.association.name} - Maksuunpanoluettelo
        </Typography>
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <td>
                {format(new Date(props.association.meetingDate), "dd.MM.yyyy")}{" "}
                Vuosikokouspäätös{" "}
                {format(new Date(props.association.meetingDate), "yyyy")}.
                eräpäivä{" "}
                {format(new Date(props.association.billDueDate), "dd.MM.yyyy")},
                muistutusmaksu{" "}
                {new Intl.NumberFormat("fi-FI", {
                  style: "currency",
                  currency: "EUR",
                }).format(props.association.reminderFee)}
                , ulosottokulumaksu{" "}
                {new Intl.NumberFormat("fi-FI", {
                  style: "currency",
                  currency: "EUR",
                }).format(props.association.foreclosureFee)}
                .
              </td>
            </tr>
            <tr>
              <td>
                Maksajia {props.users.length} kpl, kiinteistöjä{" "}
                {props.properties.length} kpl
              </td>
            </tr>
            <tr>
              <td>Yksikköhinta {props.association.unitPay} €</td>
            </tr>
            <tr>
              <td>
                Perusmaksu{" "}
                {new Intl.NumberFormat("fi-FI", {
                  style: "currency",
                  currency: "EUR",
                }).format(props.association.basePay)}{" "}
                / maksaja
              </td>
            </tr>
          </tbody>
        </table>
        {/* <Button
          variant="contained"
          color="primary"
          size={"small"}
          onClick={() => props.handleEditUser(undefined)}
        >
          Lisää
        </Button> */}
      </CardContent>
      <Paper>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Omistaja</TableCell>
              <TableCell>Kiinteistö</TableCell>
              <TableCell align="right">Yksiköt</TableCell>
              <TableCell align="right">Yksikkömaksu</TableCell>
              <TableCell align="right">Perusmaksu</TableCell>
              <TableCell align="right">Yhteensä</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedProperties).map(
              ([ownerName, userProperties], userIndex) => {
                let userTotalUnits = 0;
                let userTotalUnitPay = 0;
                const basePay = props.association.basePay; // Base pay once per user

                const userRows = userProperties.map((property, propIndex) => {
                  const unitPay = props.association.unitPay * property.units;

                  // Update user totals
                  userTotalUnits += property.units;
                  userTotalUnitPay += unitPay;

                  return (
                    <TableRow key={`${userIndex}-${propIndex}`}>
                      <TableCell>{ownerName}</TableCell>
                      <TableCell>{`${property.code} (${property.name})`}</TableCell>
                      <TableCell align="right">{property.units}</TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat("fi-FI", {
                          style: "currency",
                          currency: "EUR",
                        }).format(unitPay)}
                      </TableCell>
                      <TableCell align="right">-</TableCell>{" "}
                      {/* Base pay only in summary */}
                      <TableCell align="right">
                        {new Intl.NumberFormat("fi-FI", {
                          style: "currency",
                          currency: "EUR",
                        }).format(unitPay)}
                      </TableCell>
                    </TableRow>
                  );
                });

                // Update grand totals AFTER user summary row
                grandTotalUnits += userTotalUnits;
                grandTotalUnitPay += userTotalUnitPay;
                grandTotalBasePay += basePay;
                grandTotalAmount += userTotalUnitPay + basePay;

                return (
                  <React.Fragment key={userIndex}>
                    {userRows}
                    {/* User Summary Row */}
                    <TableRow
                      style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                    >
                      <TableCell>Yhteensä - {ownerName}</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">{userTotalUnits}</TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat("fi-FI", {
                          style: "currency",
                          currency: "EUR",
                        }).format(userTotalUnitPay)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat("fi-FI", {
                          style: "currency",
                          currency: "EUR",
                        }).format(basePay)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat("fi-FI", {
                          style: "currency",
                          currency: "EUR",
                        }).format(userTotalUnitPay + basePay)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              }
            )}

            {/* Grand Summary Row */}
            <TableRow
              style={{ fontWeight: "bold", backgroundColor: "#d0d0d0" }}
            >
              <TableCell>Kaikki yhteensä</TableCell>
              <TableCell></TableCell>
              <TableCell align="right">{grandTotalUnits}</TableCell>
              <TableCell align="right">
                {new Intl.NumberFormat("fi-FI", {
                  style: "currency",
                  currency: "EUR",
                }).format(grandTotalUnitPay)}
              </TableCell>
              <TableCell align="right">
                {new Intl.NumberFormat("fi-FI", {
                  style: "currency",
                  currency: "EUR",
                }).format(grandTotalBasePay)}
              </TableCell>
              <TableCell align="right">
                {new Intl.NumberFormat("fi-FI", {
                  style: "currency",
                  currency: "EUR",
                }).format(grandTotalAmount)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function Units() {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { association } = useAppSelector((state) => state.association);
  const { users } = useAppSelector((state) => state.user);
  const { properties } = useAppSelector((state) => state.property);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchAssociation(currentUser?.associations[0].id));
  }, [dispatch, currentUser?.associations]);

  return (
    <React.Fragment>
      <Helmet title="Tiet" />
      <Typography variant="h3" gutterBottom display="inline">
        Yksiköinti
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Link component={NavLink} to="/">
          Yksiköinti
        </Link>
        <Typography>Maksuunpanoluettelo</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {association && (
        <UnitList
          association={association}
          users={users}
          properties={properties}
          //handleEditUser={handleEditRoad}
          //handleDelete={handleDelete}
          openForm={open}
        />
      )}
    </React.Fragment>
  );
}

export default Units;
