import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { baseUrl } from "../../../config";
import { Delete, Edit, Search } from "@mui/icons-material";
import { Formik } from "formik";
import {
  FiscalYear,
  createFiscalYear,
  deleteFiscalYear,
  fetchFiscalYears,
  openFiscalYearsForm,
  setFiscalYear,
  updateFiscalYear,
} from "../../../redux/slices/accounting";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import { format } from "date-fns";
import useAuth from "../../../hooks/useAuth";
import { DatePicker } from "@mui/x-date-pickers";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Button = styled(MuiButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

function FiscalYearForm(props: any) {
  const dispatch = useAppDispatch();
  const { currentUser } = useAuth();
  const { fiscalYear } = useAppSelector((state) => state.accounting);

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (fiscalYear && fiscalYear.id !== undefined) {
        dispatch(updateFiscalYear(fiscalYear.id, values));
      } else {
        dispatch(createFiscalYear(values, currentUser?.associations[0].id));
      }

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      // eslint-disable-next-line
      dispatch(openFiscalYearsForm());
    } catch (error: unknown) {
      setStatus({ sent: false });
      setErrors({ submit: "Error" });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        fiscalYear
          ? {
              from: fiscalYear.from,
              to: fiscalYear.to,
            }
          : {
              from: new Date(),
              to: new Date(),
            }
      }
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Tilikausi
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <DatePicker
                    onChange={(value) => setFieldValue("from", value)}
                    value={values.from}
                    renderInput={(params) => (
                      <TextField
                        //error={Boolean(touched.from && errors.from)}
                        //helperText={touched.from && errors.from}
                        label="Alkaen"
                        margin="normal"
                        name="from"
                        variant="outlined"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <DatePicker
                    onChange={(value) => setFieldValue("to", value)}
                    value={values.to}
                    renderInput={(params) => (
                      <TextField
                        //error={Boolean(touched.to && errors.to)}
                        //helperText={touched.from && errors.from}
                        label="Päättyy"
                        margin="normal"
                        name="to"
                        variant="outlined"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                mr={2}
              >
                Tallenna
              </Button>
              <Button
                variant="contained"
                color="error"
                mt={3}
                onClick={props.handleOpen}
              >
                Peruuta
              </Button>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function FiscalYearList(props: any) {
  const { currentUser } = useAuth();
  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tilikaudet
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          onClick={() => props.handleEditFiscalYear(undefined)}
        >
          Lisää
        </Button>
      </CardContent>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Alku Pvm</TableCell>
              <TableCell>Loppu Pvm</TableCell>
              <TableCell>Toiminnot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Ei Tilikausia
                </TableCell>
              </TableRow>
            ) : (
              (props.rows || []).map((row: any) => (
                <TableRow key={row.id} hover>
                  <TableCell>
                    {format(new Date(row.from), "dd-MM-yyyy")}
                  </TableCell>
                  <TableCell>
                    {format(new Date(row.to), "dd-MM-yyyy")}
                  </TableCell>
                  <TableCell>
                    <Search
                      style={{ cursor: "pointer" }}
                      onClick={() => console.log("show")}
                    />{" "}
                    <Edit
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleEditFiscalYear(row)}
                    />{" "}
                    {currentUser?.role === "SUPERADMIN" ? (
                      <Delete
                        style={{ cursor: "pointer" }}
                        onClick={() => props.handleDelete(row.id)}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function FiscalYears() {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { fiscalYears, open } = useAppSelector((state) => state.accounting);

  const handleOpen = () => {
    dispatch(openFiscalYearsForm());
  };

  const handleEditFiscalYear = (fiscalYear: FiscalYear) => {
    dispatch(setFiscalYear(fiscalYear));
    dispatch(openFiscalYearsForm());
  };

  const handleDelete = (id: number) => {
    // TODO confirm
    dispatch(deleteFiscalYear(id));
  };

  useEffect(() => {
    dispatch(fetchFiscalYears(currentUser?.associations[0].id));
  }, [dispatch, currentUser?.associations]);

  return (
    <React.Fragment>
      <Helmet title="Osakkaat" />
      <Typography variant="h3" gutterBottom display="inline">
        Tilikaudet
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Link component={NavLink} to="/">
          Talous
        </Link>
        <Typography>Tilikaudet</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {open ? (
        <FiscalYearForm handleOpen={handleOpen} />
      ) : (
        <FiscalYearList
          rows={fiscalYears}
          handleEditFiscalYear={handleEditFiscalYear}
          handleDelete={handleDelete}
          openForm={open}
        />
      )}
    </React.Fragment>
  );
}

export default FiscalYears;
