import { Dispatch, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export interface Association {
  id?: number;
  name: string;
  meetingDate: Date;
  billDueDate: Date;
  basePay: number;
  unitPay: number;
  reminderFee: number;
  foreclosureFee: number;
  roadId: number;
}

export interface AssociationState {
  association: Association | undefined;
  associations: Association[];
  open: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: AssociationState = {
  association: undefined,
  associations: [],
  open: false,
  status: "idle",
};

export const fetchAssociations = createAsyncThunk(
  "assocications/fetchAssociations",
  async (associationId: number) => {
    const response = await axios(
      `${baseUrl}/associations?associationId=${associationId}`
    );
    const data = await response.data;
    return data;
  }
);

export const fetchAssociation = createAsyncThunk(
  "assocications/fetchAssociation",
  async (associationId: number) => {
    const response = await axios(
      `${baseUrl}/association?associationId=${associationId}`
    );
    const data = await response.data;
    return data;
  }
);

const associationSlice = createSlice({
  name: "association",
  initialState,
  reducers: {
    setAssociation(state, action) {
      state.association = action.payload;
    },
    addAssociation(state, action) {
      state.associations.push(action.payload);
    },
    updateAssociation(state, action) {
      const associationId = state.associations.findIndex(
        (r) => r.id === action.payload.id
      );
      state.associations[associationId] = action.payload;
    },
    deleteAssociation(state, action) {
      state.associations = state.associations.filter(
        (r: any) => r.id !== action.payload
      );
    },
    setOpen(state, action) {
      state.open = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssociations.fulfilled, (state, action) => {
      state.associations = action.payload;
    });
    builder.addCase(fetchAssociation.fulfilled, (state, action) => {
      state.association = action.payload;
    });
  },
});

export function setAssociation(association: any) {
  return async (dispatch: Dispatch) => {
    dispatch(associationSlice.actions.setAssociation(association));
  };
}

export function createAssociation(association: any) {
  return async (dispatch: Dispatch) => {
    const response = await axios.post(`${baseUrl}/associations`, association);
    dispatch(associationSlice.actions.addAssociation(response.data));
  };
}

export function updateAssociation(id: number, association: any) {
  return async (dispatch: Dispatch) => {
    const response = await axios.put(
      `${baseUrl}/associations/${id}`,
      association
    );
    dispatch(associationSlice.actions.updateAssociation(response.data));
  };
}

export function deleteAssociation(id: number) {
  return async (dispatch: Dispatch) => {
    await axios.delete(`${baseUrl}/associations/${id}`);
    dispatch(associationSlice.actions.deleteAssociation(id));
  };
}

export function openAssociationForm(open: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch(associationSlice.actions.setOpen(open));
  };
}

export default associationSlice.reducer;
