import { Dispatch, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export interface User {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  properties?: any;
}

export interface UserState {
  user: User | undefined;
  users: User[];
  open: boolean;
  editable: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: UserState = {
  user: undefined,
  users: [],
  open: false,
  editable: false,
  status: "idle",
};

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (associationId: number) => {
    const response = await axios(
      `${baseUrl}/users?associationId=${associationId}`
    );
    const data = await response.data;
    return data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    addUser(state, action) {
      state.users.push(action.payload);
    },
    updateUser(state, action) {
      const userId = state.users.findIndex((u) => u.id === action.payload.id);
      state.users[userId] = action.payload;
    },
    deleteUser(state, action) {
      state.users = state.users.filter((u: any) => u.id !== action.payload);
    },
    setOpen(state, action) {
      state.open = action.payload;
    },
    setEditable(state) {
      state.editable = !state.editable;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
  },
});

export function setUser(user: any) {
  return async (dispatch: Dispatch) => {
    dispatch(userSlice.actions.setUser(user));
  };
}

export function createUser(user: any, association: any) {
  return async (dispatch: Dispatch) => {
    //console.log("road", road);
    const response = await axios.post(`${baseUrl}/users`, {
      user,
      association,
    });
    dispatch(userSlice.actions.addUser(response.data));
  };
}

export function updateUser(id: number, user: any) {
  return async (dispatch: Dispatch) => {
    const response = await axios.put(`${baseUrl}/users/${id}`, user);
    dispatch(userSlice.actions.updateUser(response.data));
  };
}

export function deleteUser(id: number) {
  return async (dispatch: Dispatch) => {
    await axios.delete(`${baseUrl}/users/${id}`);
    dispatch(userSlice.actions.deleteUser(id));
  };
}

export function editUserForm() {
  return async (dispatch: Dispatch) => {
    dispatch(userSlice.actions.setEditable());
  };
}

export function openUserForm(open: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch(userSlice.actions.setOpen(open));
  };
}

export default userSlice.reducer;
