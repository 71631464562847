import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";

import {
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useAuth from "../../hooks/useAuth";
import { format } from "date-fns";
import {
  createConversation,
  fetchConversation,
  fetchConversations,
  updateConversation,
} from "../../redux/slices/conversation";
import { useFormik } from "formik";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Button = styled(MuiButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

function ConversationList({
  rows,
  onRowClick,
}: {
  rows: any[];
  onRowClick: (row: any) => void;
}) {
  const { currentUser } = useAuth();

  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Keskustelut
        </Typography>
      </CardContent>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Lähettäjä</TableCell>
              <TableCell>Pvm</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Ei uusia viestejä
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row) => {
                const sender =
                  currentUser?.id !== row.user1.id ? row.user1 : row.user2;

                return (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={() => onRowClick(row)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>
                      {sender.id === 1
                        ? "Asiakaspalvelu"
                        : `${sender.firstName} ${sender.lastName}`}
                    </TableCell>
                    <TableCell>
                      {format(
                        new Date(row.lastMessageTimestamp),
                        "dd-MM-yyyy HH:mm"
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function ConversationDetail(props: any) {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { conversation } = useAppSelector((state) => state.conversation);
  const { conversationId, onSendReply } = props;

  useEffect(() => {
    dispatch(fetchConversation(conversationId));
  }, [dispatch, conversationId]);

  const validationSchema = Yup.object().shape({
    content: Yup.string()
      .min(3, "Viestin tulee olla vähintään 3 merkkiä pitkä")
      .required("Viesti ei voi olla tyhjä"),
  });

  const formik = useFormik({
    initialValues: { content: "" },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!conversation || conversation.length === 0) {
        console.error("No conversation data available");
        return;
      }

      const lastMessage = conversation[conversation.length - 1]; // Last message in the conversation

      // Identify recipient: The other user in the conversation
      const recipientId =
        lastMessage.sender.id === currentUser?.id
          ? lastMessage.recipient.id
          : lastMessage.sender.id;

      dispatch(updateConversation(recipientId, values.content));

      resetForm();
    },
  });

  const getMessageSenderName = (message: any) => {
    if (!message || !message.sender) {
      return "Unknown";
    }

    // If the sender is the admin (ID 1), return "Ylläpito"
    if (message.sender.id === 1) {
      return "Asiakaspalvelu";
    }

    // Check if the sender is the current user
    if (message.sender.id === currentUser?.id) {
      return "Sinä";
    }

    // Return sender's full name
    return `${message.sender.firstName} ${message.sender.lastName}`;
  };

  return (
    <Card mb={6}>
      <CardContent>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {conversation?.map((message: any) => (
            <div
              key={message.id}
              style={{
                marginBottom: "16px",
                padding: "10px",
                borderRadius: "10px",
                maxWidth: "70%",
                backgroundColor:
                  message.sender.id === currentUser?.id ? "#DCF8C6" : "#EAEAEA",
                alignSelf:
                  message.sender.id === currentUser?.id
                    ? "flex-end"
                    : "flex-start",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                {getMessageSenderName(message)}
              </Typography>
              <Typography
                variant="body2"
                style={{ marginTop: "4px", whiteSpace: "pre-wrap" }}
              >
                {message.content}
              </Typography>
              <Typography
                variant="caption"
                style={{ marginTop: "4px", textAlign: "right" }}
              >
                {format(new Date(message.timestamp), "dd-MM-yyyy HH:mm")}
              </Typography>
            </div>
          ))}
        </div>

        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Vastaa"
            multiline
            rows={3}
            fullWidth
            sx={{
              marginBottom: 2,
              "& textarea": {
                resize: "vertical", // Allow manual resizing
                minHeight: "50px", // Prevent shrinking too much
                maxHeight: "300px", // Prevent excessive height
              },
            }}
            name="content"
            value={formik.values.content}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.content && Boolean(formik.errors.content)}
            helperText={formik.touched.content && formik.errors.content}
          />

          <Button
            variant="contained"
            color="primary"
            mt={3}
            mr={2}
            type="submit"
            disabled={!formik.isValid || formik.isSubmitting}
          >
            Lähetä
          </Button>

          <Button
            variant="contained"
            color="error"
            mt={3}
            onClick={props.onSelectedConversation}
          >
            Peruuta
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

function Conversation() {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { conversations } = useAppSelector((state) => state.conversation);

  const [selectedConversation, setSelectedConversation] = useState<any>(null);

  const handleRowClick = (conversation: any) => {
    setSelectedConversation(conversation);
  };

  const handleSelectedConversation = () => {
    setSelectedConversation(null);
  };

  const handleSendReply = (reply: string) => {
    if (!reply.trim()) return;

    // Simulate sending a reply
    const updatedMessages = [
      ...selectedConversation.messages,
      {
        id: Date.now(), // Temporary ID
        sender: { firstName: "You", lastName: "" },
        timestamp: new Date().toISOString(),
        content: reply,
      },
    ];
    setSelectedConversation({
      ...selectedConversation,
      messages: updatedMessages,
    });

    // Ideally, dispatch an action to send the reply to the backend
  };

  useEffect(() => {
    // TODO
    dispatch(fetchConversations());
  }, [dispatch, currentUser]);

  return (
    <React.Fragment>
      <Helmet title="Keskustelut" />
      <Typography variant="h3" gutterBottom display="inline">
        Keskustelut
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Keskustelut</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {selectedConversation ? (
        <ConversationDetail
          onSelectedConversation={handleSelectedConversation}
          conversationId={selectedConversation.id}
          onSendReply={handleSendReply}
        />
      ) : (
        <ConversationList rows={conversations} onRowClick={handleRowClick} />
      )}
    </React.Fragment>
  );
}

export default Conversation;
