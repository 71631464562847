import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { Box, spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { getRoadInfo } from "../../services/vayvaService";

const UserRoot = styled.div`
  max-width: 540px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface Step1Props {
  currentStep: number;
  membership: any;
}

export const Step1: React.FC<Step1Props> = ({ currentStep, membership }) => {
  if (currentStep !== 1) return null;

  return (
    <>
      <p>Tilaus: {membership}</p>
      <Field name="firstName" my={3}>
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Etunimi"
            variant="outlined"
            fullWidth
            error={!!form.errors.firstName && form.touched.firstName}
            helperText={<ErrorMessage name="firstName" />}
            my={2}
          />
        )}
      </Field>
      <Field name="lastName">
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Sukunimi"
            variant="outlined"
            fullWidth
            error={!!form.errors.lastName && form.touched.lastName}
            helperText={<ErrorMessage name="lastName" />}
            my={2}
          />
        )}
      </Field>
      <Field name="email">
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Sähköposti"
            variant="outlined"
            fullWidth
            error={!!form.errors.email && form.touched.email}
            helperText={<ErrorMessage name="email" />}
            my={2}
          />
        )}
      </Field>
      <Field name="phone">
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Puhelinnumero"
            variant="outlined"
            fullWidth
            error={!!form.errors.phone && form.touched.phone}
            helperText={<ErrorMessage name="phone" />}
            my={2}
          />
        )}
      </Field>
      <Field name="password">
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Salasana"
            variant="outlined"
            type="password"
            fullWidth
            error={!!form.errors.password && form.touched.password}
            helperText={<ErrorMessage name="password" />}
            my={2}
          />
        )}
      </Field>
      <Field name="passwordConfirmation">
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Vahvista salasana"
            variant="outlined"
            type="password"
            fullWidth
            error={
              !!form.errors.passwordConfirmation &&
              form.touched.passwordConfirmation
            }
            helperText={<ErrorMessage name="passwordConfirmation" />}
            my={2}
          />
        )}
      </Field>
      <Field name="termsAccepted" type="checkbox">
        {({ field, form }: any) => (
          <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <label>
              <input
                {...field}
                type="checkbox"
                checked={field.value}
                style={{ marginRight: "0.5rem" }}
              />
              Hyväksyn{" "}
              <a
                href="/terms"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                käyttöehdot ja tietosuojaselosteen
              </a>
            </label>
            {form.errors.termsAccepted && form.touched.termsAccepted && (
              <p style={{ color: "#d32f2f", fontSize: "0.8rem" }}>
                {form.errors.termsAccepted}
              </p>
            )}
          </div>
        )}
      </Field>
    </>
  );
};

interface Step2Props {
  currentStep: number;
}

export const Step2: React.FC<Step2Props> = ({ currentStep }) => {
  if (currentStep !== 2) return null;

  return (
    <>
      <Field name="streetName" my={3}>
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Tien nimi"
            variant="outlined"
            fullWidth
            error={!!form.errors.streetName && form.touched.streetName}
            helperText={<ErrorMessage name="streetName" />}
            my={2}
          />
        )}
      </Field>
      <Field name="municipality">
        {({ field, form }: any) => (
          <TextField
            {...field}
            label="Kunta"
            variant="outlined"
            fullWidth
            error={!!form.errors.municipality && form.touched.municipality}
            helperText={<ErrorMessage name="municipality" />}
            my={2}
          />
        )}
      </Field>
    </>
  );
};

function SignUp() {
  const navigate = useNavigate();
  const location = useLocation();
  const { signUp } = useAuth();

  const [success, setSuccess] = useState<boolean>(false);
  const [membership, setMembership] = useState<string>("basic");
  const [error, setError] = useState<string | null>(null);

  const validationSchema = [
    Yup.object({
      firstName: Yup.string().required("Etunimi on pakollinen"),
      lastName: Yup.string().required("Sukunimi on pakollinen"),
      email: Yup.string()
        .email("Virheellinen sähköpostiosoite")
        .required("Sähköposti on pakollinen"),
      phone: Yup.string()
        .matches(/^(\+?[0-9]{1,4})?([0-9]{10})$/, "Virheellinen puhelinnumero")
        .required("Puhelinnumero on pakollinen"),
      password: Yup.string()
        .min(6, "Salasanan tulee olla vähintään 6 merkkiä pitkä")
        .required("Salasana on pakollinen"),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Salasanat eivät täsmää")
        .required("Salasanan vahvistaminen on pakollinen"),
      termsAccepted: Yup.boolean()
        .oneOf([true], "Sinun tulee hyväksyä käyttöehdot ja tietosuojaseloste")
        .required("Sinun tulee hyväksyä käyttöehdot ja tietosuojaseloste"),
    }),
    Yup.object({
      streetName: Yup.string().required("Tien nimi on pakollinen"),
      municipality: Yup.string().required("Kunta on pakollinen"),
    }),
  ];

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    passwordConfirmation: "",
    streetName: "",
    municipality: "",
    termsAccepted: false,
  };

  const [currentStep, setCurrentStep] = useState(1);

  const isLastStep = currentStep === validationSchema.length;

  const handleSubmit = (values: any, actions: any) => {
    if (isLastStep) {
      try {
        getRoadInfo(values.streetName, values.municipality).then((res) => {
          const isValidRoad = !res.data.features[0].properties.virheet;

          if (isValidRoad) {
            signUp({
              email: values.email,
              password: values.password,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              streetName: values.streetName,
              municipality: values.municipality,
              membership: membership,
            });
            setSuccess(true);
          } else {
            setError(
              "Tie ei kelpaa, ota yhteys asiakaspalvelu@tiehoitokunta.fi"
            );
          }
        });

        // navigate("/login");
      } catch (error: any) {
        setError("Rekisteröinti epäonnistui");
      }
    } else {
      // Move to the next step
      setCurrentStep((prevStep) => prevStep + 1);
    }
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const prevStep = () => setCurrentStep((prevStep) => prevStep - 1);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const membershipParam = params.get("membership");

    if (membership) {
      setMembership(membershipParam || "basic");
    }
  }, [location.search, membership]);

  if (success) {
    return (
      <>
        <p>Aktivoi tunnuksesi sähköpostista löytyvästä linkistä.</p>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
            sx={{ mr: 1 }}
          >
            Kirjaudu
          </Button>
        </Box>
      </>
    );
  } else {
    return (
      <UserRoot>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema[currentStep - 1]}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              {error !== null ? <p style={{ color: "red" }}>{error}</p> : null}
              <Step1 currentStep={currentStep} membership={membership} />
              <Step2 currentStep={currentStep} />

              <div>
                <Button
                  type={"submit"}
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {isLastStep ? "Lähetä" : "Seuraava"}
                </Button>
                {currentStep > 1 && (
                  <Button
                    style={{ marginRight: "8px" }}
                    type={"button"}
                    color="primary"
                    onClick={prevStep}
                    fullWidth
                  >
                    Takaisin
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </UserRoot>
    );
  }
}

export default SignUp;
