import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ArrowUp, ArrowDown } from "react-feather";
import axios from "axios";
import { baseUrl } from "../../../config";
import { format } from "date-fns";
import { fetchJournal } from "../../../redux/slices/accounting";
import useAuth from "../../../hooks/useAuth";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Journal(props: any) {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { journal } = useAppSelector((state) => state.accounting);

  // const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(
      fetchJournal({
        associationId: currentUser?.associations[0].id,
        from: "2020-01-01",
        to: "2025-12-31",
      })
    );
  }, [dispatch, currentUser?.userRoadsRoad]);

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Päiväkirja
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Link component={NavLink} to="/">
          Talous
        </Link>
        <Typography>Päiväkirja</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Päiväkirja
              </Typography>

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Tilinumero</TableCell>
                    <TableCell>Kuvaus</TableCell>
                    <TableCell>Debet (€)</TableCell>
                    <TableCell>Kredit (€)</TableCell>
                    <TableCell>Pvm</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(journal || []).map((row: any) => (
                    <TableRow key={row.id} hover>
                      <TableCell>{row.accountCode}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("fi-FI", {
                          style: "currency",
                          currency: "EUR",
                        }).format(row.debit)}
                      </TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("fi-FI", {
                          style: "currency",
                          currency: "EUR",
                        }).format(row.credit)}
                      </TableCell>
                      <TableCell>
                        {format(new Date(row.date), "dd-MM-yyyy HH:mm")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Journal;
