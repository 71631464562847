import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { baseUrl } from "../../config";
import { Delete, Download, Edit, Search } from "@mui/icons-material";
import { Formik } from "formik";
import {
  createUser,
  deleteUser,
  fetchUsers,
  openUserForm,
  setUser,
  updateUser,
} from "../../redux/slices/user";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useAuth from "../../hooks/useAuth";
import { uploadFile, fetchFiles } from "../../redux/slices/file";
import { format } from "date-fns";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Button = styled(MuiButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

function UserForm(props: any) {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (user && user.id !== undefined) {
        dispatch(updateUser(user.id, values));
      } else {
        dispatch(createUser(values, currentUser?.associations[0]));
      }

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      // eslint-disable-next-line
      //dispatch(openUserForm());
    } catch (error: unknown) {
      setStatus({ sent: false });
      setErrors({ submit: "Error" });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        user
          ? {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
            }
          : {
              firstName: "",
              lastName: "",
              email: "",
            }
      }
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Osakkaat
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    name="firstName"
                    label="Etunimi"
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name="lastName"
                    label="Sukunimi"
                    value={values.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    name="email"
                    label="Sähköposti"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                mr={2}
              >
                Tallenna
              </Button>
              <Button
                variant="contained"
                color="error"
                mt={3}
                onClick={props.handleOpen}
              >
                Peruuta
              </Button>
            </form>
            {user !== undefined ? (
              <>
                <Typography variant="h6" gutterBottom>
                  Kiinteistöt
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nimi</TableCell>
                      <TableCell>Tunnus</TableCell>
                    </TableRow>
                    {(user?.properties || []).map((p: any) => (
                      <TableRow>
                        <TableCell>{p.name}</TableCell>
                        <TableCell>{p.code}</TableCell>
                      </TableRow>
                    ))}
                  </TableHead>
                </Table>
              </>
            ) : null}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function FileList(props: any) {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      // Dispatch the uploadFile action with the file and associationId
      dispatch(
        uploadFile({ file, associationId: currentUser?.associations[0].id })
      );
    }
  };

  const handleDownload = async (filename: any) => {
    try {
      const response = await fetch(`${baseUrl}/files/download/${filename}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a blob from the response
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link element, set the URL and click it
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set the file name for download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link and revoke the object URL
      link?.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tiedostot
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size={"small"}
          onClick={handleButtonClick}
        >
          Lisää
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </CardContent>
      <Paper>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Nimi</TableCell>
              <TableCell>Tyyppi</TableCell>
              <TableCell>Koko (mb)</TableCell>
              <TableCell>Luotu</TableCell>
              <TableCell>Toiminnot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Ei tiedostoja
                </TableCell>
              </TableRow>
            ) : (
              (props.rows || []).map((row: any) => (
                <TableRow key={row.id} hover>
                  <TableCell>{row.originalname}</TableCell>
                  <TableCell>{row.mimetype}</TableCell>
                  <TableCell>{(row.size / (1024 * 1024)).toFixed(2)}</TableCell>
                  <TableCell>
                    {format(new Date(row.createdAt), "dd-MM-yyyy HH:mm")}
                  </TableCell>
                  <TableCell>
                    <Download
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDownload(row.filename)}
                    />{" "}
                    {/* <Search
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleShowUser(row)}
                    />{" "}
                    <Edit
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleEditUser(row)}
                    />{" "} */}
                    {currentUser?.role === "SUPERADMIN" ? (
                      <Delete
                        style={{ cursor: "pointer" }}
                        //onClick={() => props.handleDelete(row)}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function Files() {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();
  const { files } = useAppSelector((state) => state.file);

  // const handleOpen = () => {
  //   dispatch(openUserForm());
  // };

  // const handleShowUser = (user: any) => {
  //   dispatch(setUser(user));
  //   dispatch(openUserForm());
  // };

  // const handleEditUser = (user: User | undefined) => {
  //   dispatch(setUser(user));
  //   dispatch(openUserForm());
  // };

  // const handleDelete = (user: User) => {
  //   // TODO confirm
  //   dispatch(deleteUser(user.id));
  // };

  useEffect(() => {
    dispatch(fetchFiles(currentUser?.associations[0].id));
  }, [dispatch, currentUser]);

  return (
    <React.Fragment>
      <Helmet title="Tiedostot" />
      <Typography variant="h3" gutterBottom display="inline">
        Tiedostot
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Tiedostot</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <FileList
        rows={files}
        // handleShowUser={handleShowUser}
        // handleEditUser={handleEditUser}
        // handleDelete={handleDelete}
        // openForm={open}
      />

      {/* {open ? (
        <UserForm handleOpen={handleOpen} />
      ) : (
        <UserList
          rows={users}
          handleShowUser={handleShowUser}
          handleEditUser={handleEditUser}
          handleDelete={handleDelete}
          openForm={open}
        />
      )} */}
    </React.Fragment>
  );
}

export default Files;
